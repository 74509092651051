import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Heading } from '../chakra/Heading'
import Div from '../Div'
import theme from '../../constants/theme'
import useResponsive from '../../utils/hooks/useResponsive'

const styles = {
  heading: isDark => css`
    color: ${isDark ? '#fff' : theme.colors.secondary.base}
    line-height: 1.15 !important;
    text-align: center;
  `,
  subHeading: (isDark, subtitleMarginTop) => css`
    color: ${isDark ? theme.colors.text.accentedGray : theme.colors.accented.base};
    text-align: center;
    margin-top: ${subtitleMarginTop};
  `,
  mainFontSizes: ['30px', '35px', '40px', '45px'],
  mainMarginTop: ['54px', '72px'],
  secondFontSizes: ['17px', '19px', '20px', '22px'],
}

function SectionTitleSubtitle({ title, subtitle, id }) {
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const subtitleMarginTop = useResponsive('8px', '10px', '12px')

  return (
    <Div mt={styles.mainMarginTop} id={id}>
      <Heading
        block
        as="h3"
        fontWeight="extrabold"
        fontSize={styles.mainFontSizes}
        css={styles.heading(isDark)}
        className="not-selectable"
      >
        {title}
      </Heading>
      {subtitle && (
        <Heading
          block
          as="h4"
          fontWeight="medium"
          fontSize={styles.secondFontSizes}
          css={styles.subHeading(isDark, subtitleMarginTop)}
        >
          {subtitle}
        </Heading>
      )}
    </Div>
  )
}

export default SectionTitleSubtitle
